<template>
  <div class="Center">
    <nav-vue index="2" />
    <div class="banner">
      <img class="banner__img" :src="config.research_banner" alt="">
    </div>
    <img v-if="!isphone" class="content__could" src="@/assets/image/rd/could.png" alt="" />
    <!-- pc页 -->
    <div v-if="!isphone" class="content">
      <div class="content__box">
        <ul class="content__box__left">
          <li v-for="(item,index) in researchData" :key="index"  @click="Onlist(index)" :class="{'content__box__left-item':true,active:listActive===index} ">
            {{item.title}}
          </li>
        </ul>
        <img class="content__lone" src="@/assets/image/rd/lines.png">
        <div class="content__box__right" v-html="content"></div>
      </div>
    </div>
    <!-- 手机页 -->
    <div v-else class="content">
      <div class="content__box">
        <ul class="content__box__title">
          <li
            v-for="(item, index) in researchData"
            :key="index"
            @click="Onlist(index)"
            :class="{
              'content__box__title-item': true,
              active: listActive === index,
            }"
          >
            {{ item.title }}
          </li>
        </ul>
          <div
          class="content__box__right"
          v-html="content"
        ></div>
      </div>
    </div>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import NavVue from '../components/nav.vue'
import FooterVue from '../components/footer.vue'
import {researchIndex} from '@/uilt/api.js'
import config from '../mixin/config'
export default {
  components: { NavVue, FooterVue },
  mixins:[config],
  data(){
    return{
      listActive:null,
      list:['产品中心','研究成果','学术论文'],
      researchData:[],
      content:'',
    }
  },
  created(){
    this.getresearchIndex()
  },
  watch:{
      listActive(e){
        this.content=this.researchData[e].content
      }
  },
  methods:{
      Onlist(e){
        this.listActive=e
      },
      getresearchIndex(){
        researchIndex().then(res=>{
          this.researchData=res
          this.listActive=0
        })
      }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
.Center {}

.banner {
  width: 100%;
  height: 400px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  position: relative;
  width: 100%;

  &__lone {
    height: 100%;
    width: 2px;
  }

  &__could {
    width: 406px;
    height: 482px;
    position: absolute;
    top: 770px;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }

  &__box {
    width: 1400px;
    height: auto;
    margin: 51px auto 57px;
    display: flex;
    background-color: #fff;
    padding: 60px  38px 41px 38px;
    box-sizing: border-box;
    z-index: 99;


    &__left {
      list-style-type: none;
      margin-right: 42px;
      height:100%;
      overflow: auto;width: 200px;
      
      &-item {
        width: 200px;
        height: 80px;
        line-height: 80px;
        border-bottom: 2px solid RGBA(215, 176, 124, 1);
        font-size: 20px;
        font-family: Songti SC;
        font-weight: 900;
        color: #000000;
        cursor: pointer;
        overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }

      .active {
        background-image: url('../assets/image/rd/LiBg.png');
        background-size: 100%;
        color: #C8934E;
        
      }
    }

    &__right {
      flex: 1;
      padding: 48px 70px 105px 53px;
      box-sizing: border-box;

      &__title {
        font-size: 20px;
        font-family: Songti SC;
        font-weight: 400;
        color: #333333;
        margin-top:0px;
      }

      &__content {
        font-size: 16px;
        font-family: Songti SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }
      &__img{
        margin-top: 57px;
        width: 998px;
        height: 398px;
        object-fit: cover;
      }
    }
  }
}
}
@media screen and (max-width: 768px) {
  .Center {
    width: 100%;
  }

  .banner {
    width: 100%;
    height: 280px;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    width: 100%;

    &__box {
      width: 100%;
      height: auto;
      padding: 30px;
      box-sizing: border-box;
      z-index: 99;
      min-height: 1000px;
      &__title {
        list-style-type: none;
        margin-right: 25px;
        width: 100%;
        display: flex;
        margin-bottom: 61px;
        overflow: auto;
       
        &-item {
           flex-shrink: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 25%;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          font-family: Songti SC;
          font-weight: 900;
          color: #000000;
          // flex: .25;
        }

        .active {
          background-image: url("../assets/image/rd/PLiBg.png");
          background-size: 100% 100%;
          color: #c8934e;
        }
      }

      &__right {
        flex: 1;
        position: relative;

        &-item {
          display: flex;
          padding: 0 30px 28px 0;
          box-sizing: border-box;
          border-bottom: 1px solid #c8934e;
          margin-bottom: 30px;
          &:hover {
            background: rgba(202, 148, 48, 0.1);
          }

          &:hover .content__box__right-item_right__button {
            background: #ca9430;
            color: #fff;
          }

          &_img {
            width: 248px;
            height: 165px;
            object-fit: cover;
          }

          &_right {
            margin-left: 20px;
            flex: 1;
            text-align: left;

            &__title {
              margin-top: 13px;
              font-size: 26px;
              font-family: Songti SC;
              font-weight: 400;
              color: #70553c;
            }

            &__date {
              margin-top: 14px;
              font-family: Songti SC;
              font-weight: 400;

              font-size: 20px;
              color: #333333;
              line-height: 9px;
            }

            &__content {
              font-size: 20px;
              margin-top: 15px;
              font-size: 14px;
              font-family: Songti SC;
              font-weight: 400;
              color: #333333;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              display: -moz-box;
              -moz-line-clamp: 3;
              -moz-box-orient: vertical;
              overflow-wrap: break-word;
              word-break: break-all;
              white-space: normal;
              overflow: hidden;
              line-height: 23px;
            }

            &__button {
              margin-top: 19px;
              width: 100px;
              height: 30px;
              border: 1px solid #e6e6e6;
              font-size: 15px;
              font-family: Songti SC;
              font-weight: 400;
              color: #000;
              line-height: 30px;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>